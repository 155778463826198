import API from './API';

const portfolio = {
  candleStickAnalysis: (params) => {
    return new API().get('/portfolio/assets/candlestick/', params);
  },
  assetsSNR: (params) => {
    return new API().get('/portfolio/snr/', params);
  },
  assetsSNRFrequencyStock: (params) => {
    return new API().get('/portfolio/snr/frequency/', params);
  },
  untouchedSNR: (params) => {
    return new API().get('/portfolio/untouched-high-low/', params);
  },
  tradeSetup: (params) => {
    const entry = params?.entry;
    const target = params?.target;
    const stop = params?.stop;
    return new API().get(`portfolio/trade-setup?entry=${entry}&target=${target}&stop=${stop}`);
  },
  upcomingEarnings: (params) => {
    return new API().get('/portfolio/traidify-market/upcoming-earnings-calendar/', params);
  },
  tradeBuilder: (params) => {
    return new API().get('/portfolio/trade-builder/', params);
  },
  tradeBuilderSetup: (params) => {
    return new API().get('/portfolio/trade-setup-builder/', params);
  },
  dashboardTradeSetupUntouchedSNR: (params) => {
    return new API().get('/portfolio/dashboard-trade-setup-untouched-snr/', params);
  },
};

export default portfolio;
