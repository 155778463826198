import { call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import portfolio from '../../services/portfolio';
import stocks from '../../services/stocks';
import technical_analysis from '../../services/technical_analysis';

import {
  RETRIEVE_ADX_DAILY,
  RETRIEVE_ADX_DAILY_OK,
  RETRIEVE_ADX_MI_STOCKS_ACTION,
  RETRIEVE_ADX_MI_STOCKS_END,
  RETRIEVE_ADX_MI_STOCKS_ERROR,
  RETRIEVE_ADX_MI_STOCKS_OK,
  RETRIEVE_ADX_STOCKS_ACTION,
  RETRIEVE_ADX_STOCKS_END,
  RETRIEVE_ADX_STOCKS_ERROR,
  RETRIEVE_ADX_STOCKS_OK,
  RETRIEVE_ADX_VIX_STOCKS_ACTION,
  RETRIEVE_ADX_VIX_STOCKS_END,
  RETRIEVE_ADX_VIX_STOCKS_ERROR,
  RETRIEVE_ADX_VIX_STOCKS_OK,
  RETRIEVE_ASSETS_SNR_FREQUENCY_STOCK_END,
  RETRIEVE_ASSETS_SNR_FREQUENCY_STOCK_ERROR,
  RETRIEVE_ASSETS_SNR_FREQUENCY_STOCK_FREQUENCY_ACTION,
  RETRIEVE_ASSETS_SNR_FREQUENCY_STOCK_OK,
  RETRIEVE_CANDLESTICK_ANALYSIS_ACTION,
  RETRIEVE_CANDLESTICK_ANALYSIS_END,
  RETRIEVE_CANDLESTICK_ANALYSIS_ERROR,
  RETRIEVE_CANDLESTICK_ANALYSIS_OK,
  RETRIEVE_COMPARATIVE_PERFORMANCE_ACTION,
  RETRIEVE_COMPARATIVE_PERFORMANCE_END,
  RETRIEVE_COMPARATIVE_PERFORMANCE_ERROR,
  RETRIEVE_COMPARATIVE_PERFORMANCE_OK,
  RETRIEVE_ETF_COMPARATIVE_PERFORMANCE_ACTION,
  RETRIEVE_ETF_COMPARATIVE_PERFORMANCE_END,
  RETRIEVE_ETF_COMPARATIVE_PERFORMANCE_ERROR,
  RETRIEVE_ETF_COMPARATIVE_PERFORMANCE_OK,
  RETRIEVE_FUND_SCREENING_ACTION,
  RETRIEVE_FUND_SCREENING_END,
  RETRIEVE_FUND_SCREENING_ERROR,
  RETRIEVE_FUND_SCREENING_OK,
  RETRIEVE_HOLDING_COMPARATIVE_PERFORMANCE_ACTION,
  RETRIEVE_HOLDING_COMPARATIVE_PERFORMANCE_END,
  RETRIEVE_HOLDING_COMPARATIVE_PERFORMANCE_ERROR,
  RETRIEVE_HOLDING_COMPARATIVE_PERFORMANCE_OK,
  RETRIEVE_INDEX_PERFORMANCE_ACTION,
  RETRIEVE_INDEX_PERFORMANCE_END,
  RETRIEVE_INDEX_PERFORMANCE_ERROR,
  RETRIEVE_INDEX_PERFORMANCE_OK,
  RETRIEVE_TIERED_CHART_DATA_ACTION,
  RETRIEVE_TIERED_CHART_DATA_END,
  RETRIEVE_TIERED_CHART_DATA_ERROR,
  RETRIEVE_TIERED_CHART_DATA_OK,
  RETRIEVE_TRADE_SETUP_UNTOUCHED_SNR_ACTION,
  RETRIEVE_TRADE_SETUP_UNTOUCHED_SNR_OK,
  RETRIEVE_TRADE_SETUP_UNTOUCHED_SNR_ERROR,
  RETRIEVE_TRADE_SETUP_UNTOUCHED_SNR_END,
  RETRIEVE_TRADE_BUILDER_SETUP_ACTION,
  RETRIEVE_TRADE_BUILDER_SETUP_END,
  RETRIEVE_TRADE_BUILDER_SETUP_ERROR,
  RETRIEVE_TRADE_BUILDER_SETUP_OK,
  RETRIEVE_TRADE_SETUP_ACTION,
  RETRIEVE_TRADE_SETUP_END,
  RETRIEVE_TRADE_SETUP_ERROR,
  RETRIEVE_TRADE_SETUP_OK,
  RETRIEVE_UNTOUCHED_SNR_ACTION,
  RETRIEVE_UNTOUCHED_SNR_END,
  RETRIEVE_UNTOUCHED_SNR_ERROR,
  RETRIEVE_UNTOUCHED_SNR_OK,
  RETRIEVE_VOLATILITY_ATR_ACTION,
  RETRIEVE_VOLATILITY_ATR_END,
  RETRIEVE_VOLATILITY_ATR_ERROR,
  RETRIEVE_VOLATILITY_ATR_OK,
  RETRIEVE_VOLATILITY_GAUGE_ACTION,
  RETRIEVE_VOLATILITY_GAUGE_END,
  RETRIEVE_VOLATILITY_GAUGE_ERROR,
  RETRIEVE_VOLATILITY_GAUGE_OK,
  SET_CANDLESTICK_ADJUSTMENTS,
} from '../constants/technical_analysis.constant';
export function* retrieveADXStocks(action) {
  try {
    const xhr = yield call(technical_analysis.adx, action);

    if (xhr.status >= 400) {
      throw xhr;
    }

    yield put({ type: RETRIEVE_ADX_STOCKS_OK, ...xhr.data });
  } catch (e) {
    console.log('error : ', e);
    yield put({ type: RETRIEVE_ADX_STOCKS_ERROR, ...e });
  } finally {
    yield put({ type: RETRIEVE_ADX_STOCKS_END });
  }
}

export function* retrieveADXVixStocks(action) {
  try {
    const xhr = yield call(technical_analysis.adx, action);

    if (xhr.status >= 400) {
      throw xhr;
    }

    yield put({ type: RETRIEVE_ADX_VIX_STOCKS_OK, ...xhr.data });
  } catch (e) {
    console.log('error : ', e);
    yield put({ type: RETRIEVE_ADX_VIX_STOCKS_ERROR, ...e });
  } finally {
    yield put({ type: RETRIEVE_ADX_VIX_STOCKS_END });
  }
}

export function* retrieveAdxMiStocks(action) {
  try {
    const xhr = yield call(technical_analysis.adxMi, {
      ticker_type: 'stock',
      params: action.params,
    });

    if (xhr.status >= 400) {
      throw xhr;
    }
    yield put({ type: RETRIEVE_ADX_MI_STOCKS_OK, ...xhr.data });
  } catch (e) {
    console.log('error : ', e);
    yield put({ type: RETRIEVE_ADX_MI_STOCKS_ERROR, ...e });
  } finally {
    yield put({ type: RETRIEVE_ADX_MI_STOCKS_END });
  }
}

export function* retrieveADXDaily(action) {
  try {
    const xhr = yield call(technical_analysis.adxDaily, action.params);

    if (xhr.status >= 400) {
      throw xhr;
    }
    yield put({ type: RETRIEVE_ADX_DAILY_OK, ...xhr.data });
  } catch (e) {
    console.log('error : ', e);
    yield put({ type: RETRIEVE_ADX_STOCKS_ERROR, ...e });
  } finally {
    yield put({ type: RETRIEVE_ADX_STOCKS_END });
  }
}

export function* retrieveTieredChartData(action) {
  try {
    const xhr = yield call(technical_analysis.tieredChartData, action);

    if (xhr.status >= 400) {
      throw xhr;
    }

    yield put({ type: RETRIEVE_TIERED_CHART_DATA_OK, ...xhr });
  } catch (e) {
    console.log('error : ', e);
    yield put({ type: RETRIEVE_TIERED_CHART_DATA_ERROR, ...e });
  } finally {
    yield put({ type: RETRIEVE_TIERED_CHART_DATA_END });
  }
}

export function* retrieveCandleStickData(action) {
  try {
    const xhr = yield call(portfolio.candleStickAnalysis, action.params);

    if (xhr.status >= 400) {
      throw xhr;
    }

    const data = xhr.data.data[0];
    // data.analysis.length = 100;

    yield put({ type: RETRIEVE_CANDLESTICK_ANALYSIS_OK, data });
  } catch (e) {
    console.log('error : ', e);
    yield put({ type: RETRIEVE_CANDLESTICK_ANALYSIS_ERROR, ...e });
  } finally {
    yield put({ type: RETRIEVE_CANDLESTICK_ANALYSIS_END });
  }
}

export function* retrieveAssetsSNRFrequencyStockData(action) {
  try {
    const xhr = yield call(portfolio.assetsSNRFrequencyStock, action.params);

    if (xhr.status >= 400) {
      throw xhr;
    }

    const data = xhr.data.data;
    const items = [];
    Object.keys(data).forEach((d) => items.push({ ...data[d] }));

    yield put({ type: RETRIEVE_ASSETS_SNR_FREQUENCY_STOCK_OK, data: items });
  } catch (e) {
    console.log('error : ', e);
    yield put({ type: RETRIEVE_ASSETS_SNR_FREQUENCY_STOCK_ERROR, ...e });
  } finally {
    yield put({ type: RETRIEVE_ASSETS_SNR_FREQUENCY_STOCK_END });
  }
}

export function* retrieveTradeSetupData(action) {
  try {
    const xhr = yield call(portfolio.tradeSetup, action.params);

    if (xhr.status >= 400) {
      throw xhr;
    }

    const data = xhr.data.data;
    const items = [];
    Object.keys(data).forEach((d) => items.push({ ...data[d] }));
    yield put({ type: RETRIEVE_TRADE_SETUP_OK, data: { ...data, ...action.params } });
  } catch (e) {
    console.log('error : ', e);
    yield put({ type: RETRIEVE_TRADE_SETUP_ERROR, ...e });
  } finally {
    yield put({ type: RETRIEVE_TRADE_SETUP_END });
  }
}

export function* retrieveIndexPerformance(action) {
  try {
    const xhr = yield call(stocks.indexPerformance, action.params);

    if (xhr.status >= 400) {
      throw xhr;
    }

    yield put({ type: RETRIEVE_INDEX_PERFORMANCE_OK, ...xhr.data });
  } catch (e) {
    console.log('error : ', e);
    yield put({ type: RETRIEVE_INDEX_PERFORMANCE_ERROR, ...e });
  } finally {
    yield put({ type: RETRIEVE_INDEX_PERFORMANCE_END });
  }
}

export function* retrieveComparativePerformance(action) {
  try {
    const xhr = yield call(stocks.comparativePerformance, action.params);

    if (xhr.status >= 400) {
      throw xhr;
    }

    yield put({
      type: RETRIEVE_COMPARATIVE_PERFORMANCE_OK,
      ...xhr.data,
    });
  } catch (e) {
    yield put({ type: RETRIEVE_COMPARATIVE_PERFORMANCE_ERROR, ...e });
  } finally {
    yield put({ type: RETRIEVE_COMPARATIVE_PERFORMANCE_END });
  }
}

export function* retrieveUntouchedSNR(action) {
  try {
    const xhr = yield call(portfolio.untouchedSNR, action.params);

    if (xhr.status >= 400) {
      throw xhr;
    }
    const data = xhr.data.data;
    let high = [];
    let low = [];
    Object.keys(data).forEach((d) => {
      high = [...high, ...data[d].untouched_high.map((uh) => ({ interval: d, ...uh }))];
      low = [...low, ...data[d].untouched_low.map((ul) => ({ interval: d, ...ul }))];
    });

    yield put({ type: RETRIEVE_UNTOUCHED_SNR_OK, low, high });
  } catch (e) {
    yield put({ type: RETRIEVE_UNTOUCHED_SNR_ERROR, ...e });
  } finally {
    yield put({ type: RETRIEVE_UNTOUCHED_SNR_END });
  }
}

export function* retrieveVolatilityGauge(action) {
  try {
    const xhr = yield call(technical_analysis.volatilityGauge, action);

    if (xhr.status >= 400) {
      throw xhr;
    }

    const { data } = xhr.data;
    const testVolatilityLineData = [];
    Object.keys(data.atr).forEach((d) => {
      if (isNaN(d) === true) {
        testVolatilityLineData.push({
          value: +data.atr[d],
          datetime: new Date(d),
        });
      }
    });
    data.atr = testVolatilityLineData;

    yield put({ type: RETRIEVE_VOLATILITY_GAUGE_OK, data });
  } catch (e) {
    yield put({ type: RETRIEVE_VOLATILITY_GAUGE_ERROR, ...e });
  } finally {
    yield put({ type: RETRIEVE_VOLATILITY_GAUGE_END });
  }
}

export function* retrieveVolatilityATR(action) {
  try {
    const xhr = yield call(technical_analysis.volatilityATR, action);

    if (xhr.status >= 400) {
      throw xhr;
    }

    const data = xhr.data.atr;

    yield put({ type: RETRIEVE_VOLATILITY_ATR_OK, data });
  } catch (e) {
    yield put({ type: RETRIEVE_VOLATILITY_ATR_ERROR, ...e });
  } finally {
    yield put({ type: RETRIEVE_VOLATILITY_ATR_END });
  }
}

export function* retrieveETFComparativePerformance(action) {
  try {
    const xhr = yield call(stocks.etfComparativePerformance, action.params);

    if (xhr.status >= 400) {
      throw xhr;
    }
    yield put({
      type: RETRIEVE_ETF_COMPARATIVE_PERFORMANCE_OK,
      data: xhr.data.data,
    });
  } catch (e) {
    yield put({ type: RETRIEVE_ETF_COMPARATIVE_PERFORMANCE_ERROR, ...e });
  } finally {
    yield put({ type: RETRIEVE_ETF_COMPARATIVE_PERFORMANCE_END });
  }
}

export function* retrieveHoldingComparativePerformance(action) {
  try {
    const xhr = yield call(stocks.holdingComparativePerformance, action.params);

    if (xhr.status >= 400) {
      throw xhr;
    }

    yield put({
      type: RETRIEVE_HOLDING_COMPARATIVE_PERFORMANCE_OK,
      data: xhr.data.data,
    });
  } catch (e) {
    yield put({ type: RETRIEVE_HOLDING_COMPARATIVE_PERFORMANCE_ERROR, ...e });
  } finally {
    yield put({ type: RETRIEVE_HOLDING_COMPARATIVE_PERFORMANCE_END });
  }
}

export function* retrieveFundScreening(action) {
  try {
    const xhr = yield call(stocks.screening, action.params);

    if (xhr.status >= 400) {
      throw xhr;
    }

    yield put({
      type: RETRIEVE_FUND_SCREENING_OK,
      data: xhr.data.data,
    });
  } catch (e) {
    yield put({ type: RETRIEVE_FUND_SCREENING_ERROR, ...e });
  } finally {
    yield put({ type: RETRIEVE_FUND_SCREENING_END });
  }
}

export function* retrieveTradeSetupUntouchedSNR(action) {
  const { enqueueSnackbar } = action;
  try {
    const xhr = yield call(portfolio.dashboardTradeSetupUntouchedSNR, action.params);
    if (xhr.status >= 400) {
      enqueueSnackbar(xhr?.data?.errors?.data?.message, { variant: 'warning' });
      return;
    }
    yield put({
      type: RETRIEVE_TRADE_SETUP_UNTOUCHED_SNR_OK,
      data: xhr.data.data,
    });
    yield put({
      type: SET_CANDLESTICK_ADJUSTMENTS,
      params: {
        adj_target: undefined,
        adj_stop: undefined,
        adj_entry: undefined,
      },
    });
  } catch (e) {
    yield put({ type: RETRIEVE_TRADE_SETUP_UNTOUCHED_SNR_ERROR, ...e });
  } finally {
    yield put({ type: RETRIEVE_TRADE_SETUP_UNTOUCHED_SNR_END });
  }
}

export function* retrieveTradeBuilderSetup(action) {
  const { enqueueSnackbar } = action;
  try {
    const xhr = yield call(portfolio.tradeBuilderSetup, action.params);
    if (xhr.status >= 400) {
      enqueueSnackbar(xhr?.data?.errors?.data?.message, { variant: 'warning' });
      return;
    }
    const parts = action.params.frequency.split(',').map((item) => item.trim());
    yield put({
      type: RETRIEVE_TRADE_BUILDER_SETUP_OK,
      data: {
        [parts?.[0]]: xhr.data.data?.[parts?.[0]],
        [parts?.[1]]: xhr.data.data?.[parts?.[1]],
        [parts?.[2]]: xhr.data.data?.[parts?.[2]],
      },
    });
    yield put({
      type: SET_CANDLESTICK_ADJUSTMENTS,
      params: {
        adj_target: undefined,
        adj_stop: undefined,
        adj_entry: undefined,
      },
    });
  } catch (e) {
    yield put({ type: RETRIEVE_TRADE_BUILDER_SETUP_ERROR, ...e });
  } finally {
    yield put({ type: RETRIEVE_TRADE_BUILDER_SETUP_END });
  }
}

export function* retrieveADXStocksSaga() {
  yield takeLatest(RETRIEVE_ADX_STOCKS_ACTION, retrieveADXStocks);
}

export function* retrieveADXVixStocksSaga() {
  yield takeLatest(RETRIEVE_ADX_VIX_STOCKS_ACTION, retrieveADXVixStocks);
}

export function* retrieveADXDailySaga() {
  yield takeLatest(RETRIEVE_ADX_DAILY, retrieveADXDaily);
}

export function* retrieveAdxMiStocksSaga() {
  yield takeLatest(RETRIEVE_ADX_MI_STOCKS_ACTION, retrieveAdxMiStocks);
}

export function* retrieveTieredChartDataSaga() {
  yield takeLatest(RETRIEVE_TIERED_CHART_DATA_ACTION, retrieveTieredChartData);
}

export function* retrieveCandleStickDataSaga() {
  yield takeLatest(RETRIEVE_CANDLESTICK_ANALYSIS_ACTION, retrieveCandleStickData);
}

export function* retrieveAssetsSNRFrequencyStockDataSaga() {
  yield takeLatest(
    RETRIEVE_ASSETS_SNR_FREQUENCY_STOCK_FREQUENCY_ACTION,
    retrieveAssetsSNRFrequencyStockData
  );
}

export function* retrieveTradeSetupDataSaga() {
  yield takeLatest(RETRIEVE_TRADE_SETUP_ACTION, retrieveTradeSetupData);
}

export function* retrieveIndexPerformanceSaga() {
  yield takeLatest(RETRIEVE_INDEX_PERFORMANCE_ACTION, retrieveIndexPerformance);
}

export function* retrieveComparativePerformanceSaga() {
  yield takeEvery(RETRIEVE_COMPARATIVE_PERFORMANCE_ACTION, retrieveComparativePerformance);
}

export function* retrieveUntouchedSNRSaga() {
  yield takeEvery(RETRIEVE_UNTOUCHED_SNR_ACTION, retrieveUntouchedSNR);
}

export function* retrieveVolatilityGaugeSaga() {
  yield takeEvery(RETRIEVE_VOLATILITY_GAUGE_ACTION, retrieveVolatilityGauge);
}

export function* retrieveVolatilityATRSaga() {
  yield takeEvery(RETRIEVE_VOLATILITY_ATR_ACTION, retrieveVolatilityATR);
}

export function* retrieveETFComparativePerformanceSaga() {
  yield takeLatest(RETRIEVE_ETF_COMPARATIVE_PERFORMANCE_ACTION, retrieveETFComparativePerformance);
}

export function* retrieveHoldingComparativePerformanceSaga() {
  yield takeLatest(
    RETRIEVE_HOLDING_COMPARATIVE_PERFORMANCE_ACTION,
    retrieveHoldingComparativePerformance
  );
}

export function* retrieveFundScreeningSaga() {
  yield takeLatest(RETRIEVE_FUND_SCREENING_ACTION, retrieveFundScreening);
}

export function* retrieveTradeBuilderSetupSaga() {
  yield takeLatest(RETRIEVE_TRADE_BUILDER_SETUP_ACTION, retrieveTradeBuilderSetup);
}

export function* retrieveTradeSetupUntouchedSNRSaga() {
  yield takeLatest(RETRIEVE_TRADE_SETUP_UNTOUCHED_SNR_ACTION, retrieveTradeSetupUntouchedSNR);
}

const technical_analysis_sagas = [
  fork(retrieveADXStocksSaga),
  fork(retrieveADXVixStocksSaga),
  fork(retrieveADXDailySaga),
  fork(retrieveAdxMiStocksSaga),
  fork(retrieveTieredChartDataSaga),
  fork(retrieveCandleStickDataSaga),
  fork(retrieveIndexPerformanceSaga),
  fork(retrieveComparativePerformanceSaga),
  fork(retrieveUntouchedSNRSaga),
  fork(retrieveVolatilityGaugeSaga),
  fork(retrieveVolatilityATRSaga),
  fork(retrieveTradeSetupDataSaga),
  fork(retrieveETFComparativePerformanceSaga),
  fork(retrieveHoldingComparativePerformanceSaga),
  fork(retrieveFundScreeningSaga),
  fork(retrieveAssetsSNRFrequencyStockDataSaga),
  fork(retrieveTradeBuilderSetupSaga),
  fork(retrieveTradeSetupUntouchedSNRSaga),
];

export default technical_analysis_sagas;
