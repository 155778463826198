import { makeStyles } from '@mui/styles';
import login_bg from '../../assets/images/signin/login_bg_2.svg';
import colors from '../../styles/colors';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    height: '100%',
    width: '100%',
    backgroundColor: 'theme.palette.base',
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('md')]: {
      // background: `url(${login_bg2})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPositionX: '20px',
    },
  },
  gridContainer: {
    zIndex: 2,
    height: '100%',
    width: '100%',
    display: 'flex',

    // '& > div': {
    //   zIndex: 'inherit',
    // },
  },
  formGridItem: {
    width: '100%',
  },
  mobileMisc: {
    background: `url(${login_bg})`,
    overflow: 'hidden',
    position: 'relative',
    minHeight: '150px',
    display: ' flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    color: theme.palette.common.white,

    '& > div': {
      backgroundColor: theme.palette.common.white,
    },

    '& h4': {
      fontWeight: 'bold',
    },
  },
  content: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  bgContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
    height: '100%',
    '& div': {
      height: '100%',
    },
  },
  imgBg: {
    height: '100%',
    borderBottomLeftRadius: '35px',
    overflow: 'hidden',
    position: 'relative',
    paddingBottom: '65px',
    marginTop: '100px',
    '& img': {
      width: '80%',
      height: '100%',
    },
  },
  signIn: {
    '& h5': {
      color: theme.palette.peter_river,
      fontWeight: 600,
      // '-webkit-background-clip': 'text',
      // '-webkit-text-fill-color': 'transparent',
    },
  },
  title: {
    background: theme.palette.gradients.blue_linear,
    fontWeight: 'bold',
    fontSize: 80,
    textAlign: 'center',
    position: 'absolute',
    top: '5%',
    width: '100%',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
  signInDesc: {
    color: theme.text[2],

    '& h6': {
      fontWeight: '400',
    },
  },
  cardImage: {
    position: 'relative',
  },

  generalError: {
    color: theme.palette.red1,
    padding: '10px 0',
  },
  textInput: {
    width: '100%',
  },
  inputAdornment: {
    height: '2.5rem',
    width: '2.5rem',
    marginRight: '-8px',

    '& button': {
      height: 'inherit',
      width: 'inherit',
    },
  },
  noAccount: {
    color: theme.text[2],

    '& a': {
      color: theme.text[3],
      textDecoration: 'underline',
      textTransform: 'unset',
      fontSize: '1rem',
      paddingTop: 0,
      paddingBottom: '2px',

      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
  },
  forgotPassword: {
    color: theme.text[0],

    '& a': {
      color: theme.text[0],
      textDecoration: 'none',
      fontWeight: 'bold',
      textTransform: 'unset',
      fontSize: '1rem',
      padding: 0,

      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
  },

  bgRoot: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    height: '100%',
  },
  bgText: {
    width: '100%',
    textAlign: 'right',
    paddingRight: '2rem',
    color: 'white',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexDirection: 'column',

    '& > p': {
      fontSize: '22rem',
      fontWeight: 'bold',
      lineHeight: '20rem',
      opacity: 0.12,
    },
  },
  bgSlope: {
    height: '150%',
    width: '50%',
    transform: 'rotate(23deg) translate(20%, -30%)',
    position: 'absolute',
    left: '-50%',
    backgroundColor: colors.base,
  },
}));

export default useStyles;
